// Resource Types
export const RESOURCES = {
  ORDER: 'Order',
  TASK: 'Task',
  CUSTOMER: 'Customer',
  PROJECT: 'Project',
  USER: 'User',
  WORKORDER: 'WorkOrder',
  CATALOG: 'Catalog'
};

// Resource Groups
export const RESOURCE_GROUPS = {
  ORDER_MANAGEMENT: 'OrderManagement',
  DESIGN_MANAGEMENT: 'DesignManagement',
  CUSTOMER_MANAGEMENT: 'CustomerManagement',
  PROJECT_MANAGEMENT: 'ProjectManagement',
  USER_MANAGEMENT: 'UserManagement',
  SYSTEM_MANAGEMENT: 'SystemManagement'
};

// Actions
export const ACTIONS = {
  ALL: 'All',
  VIEW: 'View',
  CREATE: 'Create',
  EDIT: 'Edit',
  DELETE: 'Delete'
};

// Scopes
export const SCOPES = {
  SELF: 'SELF',
  TEAM: 'TEAM',
  ALL: 'ALL'
};

// Helper function to check if an action is allowed
export const isActionAllowed = (requiredAction, grantedAction) => {
  if (grantedAction === ACTIONS.ALL) {
    return true; // ALL action grants permission for any action
  }
  return requiredAction === grantedAction;
};

// Helper function to create permissions for a resource
const createResourcePermissions = (
  resource,
  scopes = ['ALL'],
  actions = Object.values(ACTIONS)
) => {
  // If ALL action is included, no need for other actions
  if (actions.includes(ACTIONS.ALL)) {
    return scopes.map((scope) => ({
      resource,
      action: ACTIONS.ALL,
      scope
    }));
  }

  // Otherwise create permissions for each action
  return actions.flatMap((action) =>
    scopes.map((scope) => ({
      resource,
      action,
      scope
    }))
  );
};

// Predefined Roles
export const ROLES = {
  ADMIN: {
    id: 'admin',
    name: 'Administrator',
    description: 'Full system access',
    permissions: Object.values(RESOURCES).flatMap((resource) =>
      createResourcePermissions(resource, ['ALL'])
    ),
    resourceGroups: Object.values(RESOURCE_GROUPS)
  },

  ORDER_MANAGER: {
    id: 'order_manager',
    name: 'Order Manager',
    description: 'Manages orders and related processes',
    permissions: [
      ...createResourcePermissions(RESOURCES.ORDER, ['ALL']),
      ...createResourcePermissions(RESOURCES.TASK, ['TEAM']),
      ...createResourcePermissions(RESOURCES.WORKORDER, ['TEAM']),
      { resource: RESOURCES.CUSTOMER, action: ACTIONS.VIEW, scope: 'ALL' }
    ],
    resourceGroups: [RESOURCE_GROUPS.ORDER_MANAGEMENT]
  },

  DESIGNER: {
    id: 'designer',
    name: 'Designer',
    description: 'Handles design tasks and projects',
    permissions: [
      ...createResourcePermissions(RESOURCES.PROJECT, ['TEAM']),
      ...createResourcePermissions(RESOURCES.TASK, ['SELF']),
      { resource: RESOURCES.ORDER, action: ACTIONS.VIEW, scope: 'TEAM' },
      { resource: RESOURCES.CUSTOMER, action: ACTIONS.VIEW, scope: 'TEAM' }
    ],
    resourceGroups: [RESOURCE_GROUPS.DESIGN_MANAGEMENT]
  },

  CUSTOMER_SERVICE: {
    id: 'customer_service',
    name: 'Customer Service',
    description: 'Manages customer relations and inquiries',
    permissions: [
      ...createResourcePermissions(RESOURCES.CUSTOMER, ['ALL']),
      ...createResourcePermissions(RESOURCES.ORDER, ['ALL']),
      { resource: RESOURCES.TASK, action: ACTIONS.VIEW, scope: 'ALL' },
      { resource: RESOURCES.PROJECT, action: ACTIONS.VIEW, scope: 'ALL' }
    ],
    resourceGroups: [RESOURCE_GROUPS.CUSTOMER_MANAGEMENT, RESOURCE_GROUPS.ORDER_MANAGEMENT]
  },

  PROJECT_MANAGER: {
    id: 'project_manager',
    name: 'Project Manager',
    description: 'Manages projects and team coordination',
    permissions: [
      ...createResourcePermissions(RESOURCES.PROJECT, ['ALL']),
      ...createResourcePermissions(RESOURCES.TASK, ['TEAM']),
      { resource: RESOURCES.ORDER, action: ACTIONS.VIEW, scope: 'ALL' },
      { resource: RESOURCES.CUSTOMER, action: ACTIONS.VIEW, scope: 'ALL' },
      { resource: RESOURCES.USER, action: ACTIONS.VIEW, scope: 'TEAM' }
    ],
    resourceGroups: [RESOURCE_GROUPS.PROJECT_MANAGEMENT, RESOURCE_GROUPS.DESIGN_MANAGEMENT]
  }
};
