/**
 * Common status options
 */
export const STATUS_OPTIONS = {
  ACTIVE: 'active',
  INACTIVE: 'inactive',
  PENDING: 'pending',
  COMPLETED: 'completed',
  CANCELLED: 'cancelled'
};

/**
 * Common action types
 */
export const ACTION_TYPES = {
  VIEW: 'View',
  CREATE: 'Create',
  EDIT: 'Edit',
  DELETE: 'Delete',
  EXPORT: 'Export',
  IMPORT: 'Import'
};

/**
 * Common scope types for permissions
 */
export const SCOPE_TYPES = {
  SELF: 'SELF',
  TEAM: 'TEAM',
  ALL: 'ALL'
};

/**
 * Common date formats
 */
export const DATE_FORMATS = {
  DISPLAY: 'DD.MM.YYYY',
  DISPLAY_WITH_TIME: 'DD.MM.YYYY HH:mm',
  API: 'YYYY-MM-DD',
  API_WITH_TIME: 'YYYY-MM-DD HH:mm:ss'
};

/**
 * Common file types and their extensions
 */
export const FILE_TYPES = {
  IMAGE: ['.jpg', '.jpeg', '.png', '.gif'],
  DOCUMENT: ['.pdf', '.doc', '.docx', '.xls', '.xlsx'],
  ARCHIVE: ['.zip', '.rar', '.7z']
};

/**
 * Common validation patterns
 */
export const VALIDATION_PATTERNS = {
  EMAIL: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
  PHONE: /^[0-9]{10}$/,
  PASSWORD: /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/
};
