/* eslint-disable arrow-body-style */
import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';
// auth
import { AuthGuard } from 'src/auth/guard';
// layouts
import DashboardLayout from 'src/layouts/dashboard';
// components
import { LoadingScreen } from 'src/components/loading-screen';

// import { CatalogItemCreateView } from 'src/sections/ugurdokum/catalog/view';

// ----------------------------------------------------------------------

// USER
const UserProfilePage = lazy(() => import('src/pages/ugurdokum/user/profile'));
const UserCardsPage = lazy(() => import('src/pages/ugurdokum/user/cards'));
const UserListPage = lazy(() => import('src/pages/ugurdokum/user/list'));
// const UserAccountPage = lazy(() => import('src/pages/ugurdokum/user/_account'));
const UserCreatePage = lazy(() => import('src/pages/ugurdokum/user/new'));
const UserEditPage = lazy(() => import('src/pages/ugurdokum/user/edit'));

// PRODUCTION
// PROJECT MANAGEMENT
const ProjectDetailsPage = lazy(() => import('src/pages/ugurdokum/production/project/details'));
const ProjectListPage = lazy(() => import('src/pages/ugurdokum/production/project/list'));
const ProjectCreatePage = lazy(() => import('src/pages/ugurdokum/production/project/new'));
const ProjectEditPage = lazy(() => import('src/pages/ugurdokum/production/project/edit'));
const AddVariantPage = lazy(() => import('src/pages/ugurdokum/production/project/variants/new'));
const VariantDetailPage = lazy(
  () => import('src/pages/ugurdokum/production/project/variants/detail')
);

// CATALOG MANAGEMENT
const CatalogItemCreatePage = lazy(() => import('src/pages/ugurdokum/catalog/new'));
const CatalogItemListPage = lazy(() => import('src/pages/ugurdokum/catalog/list'));

// CUSTOMER MANAGEMENT
const CustomerCreatePage = lazy(() => import('src/pages/ugurdokum/customer/new'));
const CustomerListPage = lazy(() => import('src/pages/ugurdokum/customer/list'));
const LeadsListPage = lazy(() => import('src/pages/ugurdokum/customer/leads/list'));

// ORDER
const OrderListPage = lazy(() => import('src/pages/ugurdokum/order/list'));
const OrderDetailsPage = lazy(() => import('src/pages/ugurdokum/order/details'));
const OrderCreatePage = lazy(() => import('src/pages/ugurdokum/order/new'));

// WORKORDER
const WorkOrderListPage = lazy(() => import('src/pages/ugurdokum/workorder/list'));
const TaskQueuesListPage = lazy(() => import('src/pages/ugurdokum/taskqueues/list'));

const Layout = () => {
  return (
    <AuthGuard>
      <DashboardLayout>
        <Suspense fallback={<LoadingScreen />}>
          <Outlet />
        </Suspense>
      </DashboardLayout>
    </AuthGuard>
  );
};

export const appRoutes = [
  {
    path: '/',
    element: <Layout />,
    children: [
      {
        path: 'user',
        children: [
          { element: <UserProfilePage />, index: true },
          { path: 'profile', element: <UserProfilePage /> },
          { path: 'cards', element: <UserCardsPage /> },
          { path: 'list', element: <UserListPage /> },
          { path: 'new', element: <UserCreatePage /> },
          { path: ':id/edit', element: <UserEditPage /> }
          // { path: 'account', element: <UserAccountPage /> },
        ]
      },
      {
        path: 'production',
        children: [
          {
            path: 'project',
            children: [
              { element: <ProjectListPage />, index: true },
              { path: 'list', element: <ProjectListPage /> },
              { path: ':id/edit', element: <ProjectEditPage /> },
              { path: 'new', element: <ProjectCreatePage /> },
              { path: ':id', element: <ProjectDetailsPage /> },
              { path: ':id/variants/new', element: <AddVariantPage /> },
              { path: 'variants/:id', element: <VariantDetailPage /> }
            ]
          }
        ]
      },
      {
        path: 'catalog',
        children: [
          {
            path: '',
            element: <CatalogItemListPage />
          },
          {
            path: 'new',
            element: <CatalogItemCreatePage />
          }
        ]
      },
      {
        path: 'customer',
        children: [
          {
            path: '',
            element: <CustomerListPage />
          },
          {
            path: 'new',
            element: <CustomerCreatePage />
          },
          {
            path: 'leads',
            children: [{ element: <LeadsListPage />, index: true }]
          }
        ]
      },
      {
        path: 'order',
        children: [
          { element: <OrderListPage />, index: true },
          { path: 'list', element: <OrderListPage /> },
          { path: ':id', element: <OrderDetailsPage /> },
          { path: 'new', element: <OrderCreatePage /> }
        ]
      },
      {
        path: 'workorder',
        children: [
          { element: <WorkOrderListPage />, index: true },
          { path: 'list', element: <WorkOrderListPage /> }
        ]
      },
      {
        path: 'taskqueues',
        children: [
          { element: <TaskQueuesListPage />, index: true },
          { path: 'list', element: <TaskQueuesListPage /> }
        ]
      }
    ]
  }
];
