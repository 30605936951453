import {
  HubConnectionBuilder,
  HttpTransportType,
  JsonHubProtocol,
  LogLevel,
  HubConnectionState
} from '@microsoft/signalr';
import { HOST_API } from 'src/config-global';
import { CONNECTION_STATE, CONNECTION_ERROR } from 'src/constants';

export class HubManager {
  constructor() {
    this.connections = new Map();
    this.connectionStates = new Map();
    this.connectionErrors = new Map();
    this.onConnectionStateChange = null;
  }

  getConnectionConfig(hubEndpoint, token) {
    const cleanToken = token.replace('Bearer ', '');
    return {
      skipNegotiation: false,
      transport:
        HttpTransportType.WebSockets |
        HttpTransportType.ServerSentEvents |
        HttpTransportType.LongPolling,
      withCredentials: false,
      accessTokenFactory: () => {
        return cleanToken;
      },
      timeout: 30000,
      keepAliveIntervalInMilliseconds: 15000,
      serverTimeoutInMilliseconds: 30000
    };
  }

  async initializeHub(hubType, token, onStateChange, onError) {
    if (!onError || typeof onError !== 'function') {
      throw new Error('onError callback is required');
    }

    if (this.connections.has(hubType)) {
      await this.stopHub(hubType);
    }

    try {
      if (!token) {
        console.error('[HubManager] No token available');
        onError(hubType, CONNECTION_ERROR.NO_TOKEN);
        return null;
      }

      const hubEndpoint = `${HOST_API}/hubs/${hubType}`;

      const connection = new HubConnectionBuilder()
        .withUrl(hubEndpoint, this.getConnectionConfig(hubEndpoint, token))
        .withHubProtocol(new JsonHubProtocol())
        .configureLogging(LogLevel.Information)
        .withAutomaticReconnect({
          nextRetryDelayInMilliseconds: (retryContext) => {
            return Math.min(1000 * Math.pow(2, retryContext.previousRetryCount), 30000);
          }
        })
        .build();

      connection.onclose((error) => {
        this.connectionStates.set(hubType, CONNECTION_STATE.DISCONNECTED);
        onStateChange(hubType, CONNECTION_STATE.DISCONNECTED);
        if (error) {
          const errorMessage =
            error?.statusCode === 401 ? CONNECTION_ERROR.NO_TOKEN : CONNECTION_ERROR.DISCONNECTED;

          this.connectionErrors.set(hubType, errorMessage);
          onError(hubType, errorMessage);
        }
      });

      connection.onreconnecting(() => {
        this.connectionStates.set(hubType, CONNECTION_STATE.RECONNECTING);
        onStateChange(hubType, CONNECTION_STATE.RECONNECTING);
        this.connectionErrors.set(hubType, CONNECTION_ERROR.ATTEMPTING_RECONNECT);
        onError(hubType, CONNECTION_ERROR.ATTEMPTING_RECONNECT);
      });

      connection.onreconnected(() => {
        this.connectionStates.set(hubType, CONNECTION_STATE.CONNECTED);
        onStateChange(hubType, CONNECTION_STATE.CONNECTED);
        this.connectionErrors.set(hubType, null);
        onError(hubType, null);
      });

      this.connections.set(hubType, connection);
      return connection;
    } catch (error) {
      console.error('[HubManager] Error getting token:', error);
      onError(hubType, CONNECTION_ERROR.NO_TOKEN);
      return null;
    }
  }

  async startHub(hubType) {
    const connection = this.connections.get(hubType);
    if (connection) {
      try {
        if (connection.state === HubConnectionState.Connected) {
          return;
        }

        this.connectionErrors.delete(hubType);
        this.connectionStates.set(hubType, CONNECTION_STATE.CONNECTING);
        this.onConnectionStateChange?.(hubType, CONNECTION_STATE.CONNECTING);

        await connection.start();

        if (connection.state === HubConnectionState.Connected) {
          this.connectionStates.set(hubType, CONNECTION_STATE.CONNECTED);
          this.onConnectionStateChange?.(hubType, CONNECTION_STATE.CONNECTED);
          this.connectionErrors.delete(hubType);
        }
      } catch (error) {
        console.error(`[HubManager] Failed to start hub ${hubType}:`, error);
        this.handleConnectionError(hubType, error);
        throw error;
      }
    }
  }

  handleConnectionError(hubType, error) {
    const errorState =
      error.statusCode === 401 ? CONNECTION_ERROR.NO_TOKEN : CONNECTION_ERROR.FAILED_TO_CONNECT;

    this.connectionStates.set(hubType, CONNECTION_STATE.CONNECTION_ERROR);
    this.connectionErrors.set(hubType, errorState);
  }

  async stopHub(hubType) {
    const connection = this.connections.get(hubType);
    if (connection) {
      try {
        if (connection.state === 'Disconnected') {
          this.connections.delete(hubType);
          this.connectionStates.set(hubType, CONNECTION_STATE.DISCONNECTED);
          return;
        }

        await connection.stop();
        this.connections.delete(hubType);
        this.connectionStates.set(hubType, CONNECTION_STATE.DISCONNECTED);
      } catch (error) {
        console.error(`[HubManager] Error stopping ${hubType} hub:`, error);
      }
    }
  }

  getConnection(hubType) {
    return this.connections.get(hubType);
  }

  getConnectionState(hubType) {
    return this.connectionStates.get(hubType);
  }

  getConnectionError(hubType) {
    return this.connectionErrors.get(hubType);
  }

  async stopAllHubs() {
    const stopPromises = Array.from(this.connections.keys()).map((hubType) =>
      this.stopHub(hubType)
    );
    await Promise.all(stopPromises);
  }
}
