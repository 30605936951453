import { createContext, useContext, useMemo, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSnackbar } from 'src/components/snackbar';
import { useConstants } from 'src/api/ugurdokum/useConstants';
import { LoadingScreen } from 'src/components/loading-screen';
import { Button } from '@mui/material';

const REFRESH_INTERVAL = 1000 * 60 * 60; // 1 hour

const ConstantsContext = createContext(null);

export function ConstantsProvider({ children }) {
  const { enqueueSnackbar } = useSnackbar();
  const { constants, loading, error, refetch } = useConstants();

  // Set up periodic refresh
  useEffect(() => {
    const intervalId = setInterval(refetch, REFRESH_INTERVAL);
    return () => clearInterval(intervalId);
  }, [refetch]);

  // Helper functions with useCallback
  const getMaterialByCode = useCallback(
    (code) => constants?.materialTypes.find((m) => m.code === code),
    [constants]
  );

  const getVariantTypeById = useCallback(
    (id) => constants?.variantTypes.find((v) => v.id === id),
    [constants]
  );

  const getActiveVariantTypes = useCallback(
    () => constants?.variantTypes.filter((v) => v.isActive) || [],
    [constants]
  );

  // Refresh function that can be called from any component
  const refreshConstants = useCallback(async () => {
    try {
      await refetch();
      enqueueSnackbar('Sabitler başarıyla güncellendi', { variant: 'success' });
    } catch (err) {
      enqueueSnackbar('Sabitler güncellenirken hata oluştu', { variant: 'error' });
    }
  }, [refetch, enqueueSnackbar]);

  const value = useMemo(
    () => ({
      // Raw data
      constants,
      loading,
      error,
      refetch: refreshConstants,

      // Computed/derived values
      orderSourceChannels: constants?.orderSourceChannels || [],
      materialTypes: constants?.materialTypes || [],
      variantTypes: constants?.variantTypes || [],
      variantPropertiesConstants: constants?.variantProperties || [],
      availableOrderStatuses: constants?.availableOrderStatuses || [],
      availableOrderItemStatuses: constants?.availableOrderItemStatuses || [],

      // Helper functions
      getMaterialByCode,
      getVariantTypeById,
      getActiveVariantTypes
    }),
    [
      constants,
      loading,
      error,
      refreshConstants,
      getMaterialByCode,
      getVariantTypeById,
      getActiveVariantTypes
    ]
  );

  if (loading) {
    return <LoadingScreen />;
  }

  if (error) {
    console.error('Failed to load constants:', error);
    enqueueSnackbar('Sistem sabitleri yüklenirken hata oluştu', {
      variant: 'error',
      persist: true,
      action: (key) => <Button onClick={() => window.location.reload()}>Yeniden Dene</Button>
    });
    return null;
  }

  return <ConstantsContext.Provider value={value}>{children}</ConstantsContext.Provider>;
}

ConstantsProvider.propTypes = {
  children: PropTypes.node.isRequired
};

// Main hook to use constants
export function useAppConstants() {
  const context = useContext(ConstantsContext);
  if (!context) {
    throw new Error('useAppConstants must be used within a ConstantsProvider');
  }
  return context;
}

// Specific selector hooks
export function useOrderSourceChannels() {
  const { orderSourceChannels } = useAppConstants();
  return orderSourceChannels;
}

export function useMaterialTypes() {
  const { materialTypes } = useAppConstants();
  return materialTypes;
}

export function useVariantTypes(activeOnly = true) {
  const { variantTypes, getActiveVariantTypes } = useAppConstants();
  return activeOnly ? getActiveVariantTypes() : variantTypes;
}

// Specific use-case hooks
export function useMaterialByCode(code) {
  const { getMaterialByCode } = useAppConstants();
  return useMemo(() => getMaterialByCode(code), [getMaterialByCode, code]);
}

export function useVariantTypeById(id) {
  const { getVariantTypeById } = useAppConstants();
  return useMemo(() => getVariantTypeById(id), [getVariantTypeById, id]);
}

export function useVariantPropertiesConstants() {
  const { variantPropertiesConstants } = useAppConstants();
  return variantPropertiesConstants;
}

export function useAvailableOrderStatuses() {
  const { availableOrderStatuses } = useAppConstants();
  return availableOrderStatuses;
}

export function useAvailableOrderItemStatuses() {
  const { availableOrderItemStatuses } = useAppConstants();
  return availableOrderItemStatuses;
}
