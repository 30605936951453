import { ACTIONS } from 'src/auth/config/roles';

/**
 * Parse permission string into components
 * @param {string} permission - Permission string in format "resource.action[.scope]"
 * @returns {{resource: string, action: string, scope?: string}}
 */
export const parsePermission = (permission) => {
  const parts = permission.split('.');
  return {
    resource: parts[0],
    action: parts[1],
    scope: parts[2]
  };
};

/**
 * Check if an action is allowed based on granted action
 * @param {string} requiredAction - The action being requested
 * @param {string} grantedAction - The action that was granted
 * @returns {boolean}
 */
export const isActionAllowed = (requiredAction, grantedAction) => {
  if (grantedAction === ACTIONS.ALL) {
    return true; // ALL action grants permission for any action
  }
  return requiredAction === grantedAction;
};

/**
 * Check if a scope is allowed based on granted scope
 * @param {string} requiredScope - The scope being requested
 * @param {string} grantedScope - The scope that was granted
 * @returns {boolean}
 */
export const isScopeAllowed = (requiredScope, grantedScope) => {
  if (!requiredScope || !grantedScope) return true;
  if (grantedScope === 'ALL') return true;
  return requiredScope === grantedScope;
};

/**
 * Find matching permission from a list of permissions
 * @param {Array} permissions - List of permissions to check
 * @param {string} resource - Resource to match
 * @param {string} action - Action to match
 * @returns {Object|null} - Matching permission or null
 */
export const findMatchingPermission = (permissions, resource, action) => {
  return permissions.find((p) => p.resource === resource && isActionAllowed(action, p.action));
};

/**
 * Check if user has permission through roles or direct permissions
 * @param {import('../context/authorization-context').AuthUser} user
 * @param {string} permission - Permission string to check
 * @returns {boolean}
 */
export const checkPermission = (user, permission) => {
  if (!user) return false;

  const { resource, action, scope } = parsePermission(permission);

  // Check direct permissions
  const directPermission = findMatchingPermission(user.permissions, resource, action);
  if (directPermission && isScopeAllowed(scope, directPermission.scope)) {
    return true;
  }

  // Check permissions through roles
  return user.roles.some((role) => {
    const rolePermission = findMatchingPermission(role.permissions, resource, action);
    return rolePermission && isScopeAllowed(scope, rolePermission.scope);
  });
};

/**
 * Check if user has access to resource group through roles
 * @param {import('../context/authorization-context').AuthUser} user
 * @param {string} groupId - Resource group ID to check
 * @returns {boolean}
 */
export const checkResourceGroupAccess = (user, groupId) => {
  if (!user) return false;

  // Check direct resource group access
  if (user.resourceGroups.includes(groupId)) return true;

  // Check resource group access through roles
  return user.roles.some((role) => role.resourceGroups.includes(groupId));
};

/**
 * Check if user can access a specific resource
 * @param {import('../context/authorization-context').AuthUser} user
 * @param {Object} resource - Resource to check access for
 * @param {string} action - Action to check
 * @returns {boolean}
 */
export const checkResourceAccess = (user, resource, action) => {
  if (!user || !resource?.type) return false;

  // Find the applicable permission
  const directPermission = findMatchingPermission(user.permissions, resource.type, action);
  const rolePermission = user.roles
    .flatMap((r) => r.permissions)
    .find((p) => p.resource === resource.type && isActionAllowed(action, p.action));

  const permission = directPermission || rolePermission;
  if (!permission) return false;

  // Check resource group access if applicable
  if (resource.groupId && !checkResourceGroupAccess(user, resource.groupId)) {
    return false;
  }

  // Check scope-based access
  switch (permission.scope) {
    case 'ALL':
      return true;
    case 'TEAM':
      return user.teamId === resource.teamId;
    case 'SELF':
      return user.id === resource.ownerId;
    default:
      return false;
  }
};
