import PropTypes from 'prop-types';
import { useEffect, useReducer, useCallback, useMemo } from 'react';
// utils
import axios, { endpoints } from 'src/utils/axios';
//
import { AuthContext } from './auth-context';
import { isValidToken, setSession } from './utils';

// ----------------------------------------------------------------------

// NOTE:
// We only build demo at basic level.
// Customer will need to do some extra handling yourself if you want to extend the logic and other features...

// ----------------------------------------------------------------------

const initialState = {
  user: null,
  loading: true
};

const reducer = (state, action) => {
  if (action.type === 'INITIAL') {
    return {
      loading: false,
      user: action.payload.user
    };
  }
  if (action.type === 'LOGIN') {
    return {
      ...state,
      user: action.payload.user
    };
  }
  if (action.type === 'REGISTER') {
    return {
      ...state,
      user: action.payload.user
    };
  }
  if (action.type === 'LOGOUT') {
    return {
      ...state,
      user: null
    };
  }
  return state;
};

// ----------------------------------------------------------------------

const STORAGE_KEY = 'accessToken';
const REFRESH_TOKEN_KEY = 'refreshToken';
const STORAGE_TYPE_KEY = 'tokenStorageType';

// Helper function to clear all auth storage
const clearAuthStorage = () => {
  localStorage.removeItem(STORAGE_KEY);
  localStorage.removeItem(REFRESH_TOKEN_KEY);
  sessionStorage.removeItem(STORAGE_KEY);
  sessionStorage.removeItem(REFRESH_TOKEN_KEY);
  localStorage.removeItem(STORAGE_TYPE_KEY);
};

// Helper function to get current token and storage type
const getCurrentToken = () => {
  const storageType = localStorage.getItem(STORAGE_TYPE_KEY);
  const sessionToken = sessionStorage.getItem(STORAGE_KEY);
  const localToken = localStorage.getItem(STORAGE_KEY);
  const sessionRefreshToken = sessionStorage.getItem(REFRESH_TOKEN_KEY);
  const localRefreshToken = localStorage.getItem(REFRESH_TOKEN_KEY);

  // If storage type is set, use that specific storage
  if (storageType) {
    return {
      token: storageType === 'local' ? localToken : sessionToken,
      refreshToken: storageType === 'local' ? localRefreshToken : sessionRefreshToken,
      storageType
    };
  }

  // If no storage type is set but we have a token, determine type
  if (localToken) {
    return { token: localToken, refreshToken: localRefreshToken, storageType: 'local' };
  }
  if (sessionToken) {
    return { token: sessionToken, refreshToken: sessionRefreshToken, storageType: 'session' };
  }

  return { token: null, refreshToken: null, storageType: null };
};

// Helper function to set token with storage type
const setAuthToken = (token, refreshToken, remember = false) => {
  clearAuthStorage(); // Clear both storages first

  if (token) {
    const storageType = remember ? 'local' : 'session';
    if (remember) {
      localStorage.setItem(STORAGE_KEY, token);
      localStorage.setItem(REFRESH_TOKEN_KEY, refreshToken);
    } else {
      sessionStorage.setItem(STORAGE_KEY, token);
      sessionStorage.setItem(REFRESH_TOKEN_KEY, refreshToken);
    }
    localStorage.setItem(STORAGE_TYPE_KEY, storageType);
  }
};

export function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  const initialize = useCallback(async () => {
    try {
      const { token: accessToken, refreshToken, storageType } = getCurrentToken();

      if (accessToken && isValidToken(accessToken)) {
        setSession(accessToken, refreshToken, storageType === 'local');

        const response = await axios.get(endpoints.auth.me);
        const { user } = response.data;

        dispatch({
          type: 'INITIAL',
          payload: {
            user
          }
        });
      } else if (refreshToken) {
        // Try to refresh the token if we have a refresh token
        try {
          const response = await axios.post(endpoints.auth.refresh, { refreshToken });
          const {
            accessToken: newAccessToken,
            refreshToken: newRefreshToken,
            user
          } = response.data;

          setSession(newAccessToken, newRefreshToken, storageType === 'local');

          dispatch({
            type: 'INITIAL',
            payload: {
              user
            }
          });
        } catch (error) {
          console.error('Failed to refresh token:', error);
          clearAuthStorage();
          dispatch({
            type: 'INITIAL',
            payload: {
              user: null
            }
          });
        }
      } else {
        clearAuthStorage();
        dispatch({
          type: 'INITIAL',
          payload: {
            user: null
          }
        });
      }
    } catch (error) {
      console.error(error);
      clearAuthStorage();
      dispatch({
        type: 'INITIAL',
        payload: {
          user: null
        }
      });
    }
  }, []);

  useEffect(() => {
    initialize();
  }, [initialize]);

  // LOGIN
  const login = useCallback(async (email, password, remember) => {
    const data = {
      email,
      password,
      remember
    };

    const response = await axios.post(endpoints.auth.login, data);
    const { accessToken, refreshToken, user } = response.data;

    setAuthToken(accessToken, refreshToken, remember);
    setSession(accessToken, refreshToken, remember);

    dispatch({
      type: 'LOGIN',
      payload: {
        user
      }
    });
  }, []);

  // REGISTER
  const register = useCallback(async (email, password, firstName, lastName) => {
    const data = {
      email,
      password,
      firstName,
      lastName
    };

    const response = await axios.post(endpoints.auth.register, data);

    const { accessToken, user } = response.data;

    setSession(accessToken, false);

    dispatch({
      type: 'REGISTER',
      payload: {
        user
      }
    });
  }, []);

  // LOGOUT
  const logout = useCallback(async () => {
    try {
      const refreshToken = getCurrentToken().refreshToken;
      if (refreshToken) {
        // Revoke refresh token on server
        await axios.post(endpoints.auth.revokeToken, { refreshToken });
      }
    } catch (error) {
      console.error('Error revoking refresh token:', error);
    }

    setSession(null);
    clearAuthStorage();
    dispatch({
      type: 'LOGOUT'
    });
  }, []);

  // ----------------------------------------------------------------------

  const checkAuthenticated = state.user ? 'authenticated' : 'unauthenticated';

  const status = state.loading ? 'loading' : checkAuthenticated;

  const memoizedValue = useMemo(
    () => ({
      user: state.user,
      method: 'jwt',
      loading: status === 'loading',
      authenticated: status === 'authenticated',
      unauthenticated: status === 'unauthenticated',
      //
      login,
      register,
      logout
    }),
    [login, logout, register, state.user, status]
  );

  return <AuthContext.Provider value={memoizedValue}>{children}</AuthContext.Provider>;
}

AuthProvider.propTypes = {
  children: PropTypes.node
};
