import { createContext, useContext, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useAuthContext } from 'src/auth/hooks';
import { HubManager } from './HubManager';
import { CONNECTION_STATE, CONNECTION_ERROR, HUB_TYPES } from 'src/constants';

const SignalRContext = createContext(null);

export function SignalRProvider({ children }) {
  const hubManagerRef = useRef(null);
  if (!hubManagerRef.current) {
    hubManagerRef.current = new HubManager();
  }
  const hubManager = hubManagerRef.current;

  const [connectionStates, setConnectionStates] = useState({});
  const [connectionErrors, setConnectionErrors] = useState({});
  const mountedRef = useRef(true);

  const { authenticated, loading } = useAuthContext();

  useEffect(() => {
    if (!authenticated || loading) return;

    const pollConnectionStates = () => {
      let hasChanges = false;
      const newStates = {};

      Object.values(HUB_TYPES).forEach((hubType) => {
        const connection = hubManager.getConnection(hubType);
        if (connection) {
          const currentState = connection.state;
          const mappedState =
            currentState === 'Connected'
              ? CONNECTION_STATE.CONNECTED
              : CONNECTION_STATE.DISCONNECTED;

          newStates[hubType] = mappedState;
          if (connectionStates[hubType] !== mappedState) {
            hasChanges = true;
          }
        }
      });

      if (hasChanges) {
        setConnectionStates(newStates);
      }
    };

    const getPollInterval = () => {
      const anyDisconnected = Object.values(connectionStates).some(
        (state) => state !== CONNECTION_STATE.CONNECTED
      );
      return anyDisconnected ? 1000 : 5000;
    };

    const pollInterval = setInterval(pollConnectionStates, getPollInterval());
    return () => clearInterval(pollInterval);
  }, [authenticated, loading, connectionStates, hubManager]);

  useEffect(() => {
    if (!hubManager) return;

    const handleConnectionStateChange = (hubType, state) => {
      setConnectionStates((prev) => ({
        ...prev,
        [hubType]: state
      }));
    };

    hubManager.onConnectionStateChange = handleConnectionStateChange;

    return () => {
      hubManager.onConnectionStateChange = null;
    };
  }, [hubManager]);

  useEffect(() => {
    return () => {
      mountedRef.current = false;
      hubManager.stopAllHubs();
    };
  }, []);

  const getCurrentToken = () => {
    const storageType = localStorage.getItem('tokenStorageType');
    const sessionToken = sessionStorage.getItem('accessToken');
    const localToken = localStorage.getItem('accessToken');
    return storageType === 'local' ? localToken : sessionToken || localToken;
  };

  const handleStateChange = (hubType, state) => {
    if (!mountedRef.current) return;

    setConnectionStates((prev) => ({ ...prev, [hubType]: state }));
  };

  const handleError = (hubType, error) => {
    if (!mountedRef.current) return;
    setConnectionErrors((prev) => ({ ...prev, [hubType]: error }));
  };

  const initializeHub = async (hubType, token, stateCallback, errorCallback) => {
    try {
      return await hubManager.initializeHub(hubType, token, stateCallback, errorCallback);
    } catch (error) {
      errorCallback(hubType, CONNECTION_ERROR.FAILED_TO_CONNECT);
      return null;
    }
  };

  const handleConnectionChange = async () => {
    if (!mountedRef.current) return;

    if (!authenticated) {
      await hubManager.stopAllHubs();
      Object.values(HUB_TYPES).forEach((hubType) => {
        handleStateChange(hubType, CONNECTION_STATE.DISCONNECTED);
      });
      setConnectionErrors({});
      return;
    }

    try {
      for (const hubType of Object.values(HUB_TYPES)) {
        if (!mountedRef.current) return;
        const connection = await initializeHub(
          hubType,
          getCurrentToken(),
          handleStateChange,
          handleError
        );
        if (connection) {
          await hubManager.startHub(hubType);
          await new Promise((resolve) => setTimeout(resolve, 1000));
        }
      }
    } catch (error) {
      console.error('Connection failed:', error);
      if (error.hubType) {
        handleError(error.hubType, CONNECTION_ERROR.FAILED_TO_CONNECT);
      }
    }
  };

  useEffect(() => {
    if (!mountedRef.current || loading) return;
    handleConnectionChange();
  }, [authenticated, loading]);

  const getConnection = (hubType) => hubManager.getConnection(hubType);

  const getConnectionState = (hubType) => {
    const state = connectionStates[hubType] || CONNECTION_STATE.DISCONNECTED;

    return state;
  };

  const getConnectionError = (hubType) => connectionErrors[hubType];

  const reconnect = async (hubType) => {
    await hubManager.stopHub(hubType);
    return initializeHub(hubType, getCurrentToken(), handleStateChange, handleError);
  };

  const contextValue = {
    getConnection,
    getConnectionState,
    getConnectionError,
    setConnectionError: handleError,
    reconnect
  };

  return <SignalRContext.Provider value={contextValue}>{children}</SignalRContext.Provider>;
}

SignalRProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export const useSignalR = () => {
  const context = useContext(SignalRContext);
  if (!context) {
    throw new Error('useSignalR must be used within a SignalRProvider');
  }
  return context;
};
