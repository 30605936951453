import 'src/locales/i18n';
import 'simplebar-react/dist/simplebar.min.css';
import 'yet-another-react-lightbox/styles.css';
import 'yet-another-react-lightbox/plugins/captions.css';
import 'yet-another-react-lightbox/plugins/thumbnails.css';
import 'mapbox-gl/dist/mapbox-gl.css';
import 'react-quill/dist/quill.snow.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'react-lazy-load-image-component/src/effects/blur.css';

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import Router from 'src/routes/sections';
import ThemeProvider from 'src/theme';
import { useScrollToTop } from 'src/hooks/use-scroll-to-top';
import ProgressBar from 'src/components/progress-bar';
import MotionLazy from 'src/components/animate/motion-lazy';
import SnackbarProvider from 'src/components/snackbar/snackbar-provider';
import { SettingsProvider, SettingsDrawer } from 'src/components/settings';
import { AuthProvider } from 'src/auth/context/jwt';
import { AuthorizationProvider } from 'src/auth/context/authorization-context';
import { ConstantsProvider } from 'src/constants';
import { SignalRProvider } from 'src/contexts/signalr/SignalRProvider';
import { useAuthContext } from 'src/auth/hooks/use-auth-context';

// Component to render the app content
function AppContent() {
  const { user } = useAuthContext();

  return (
    <AuthorizationProvider user={user}>
      <SettingsDrawer />
      <ProgressBar />
      <Router />
    </AuthorizationProvider>
  );
}

export default function App() {
  useScrollToTop();

  return (
    <AuthProvider>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <SettingsProvider
          defaultSettings={{
            themeMode: 'dark', // Force dark theme
            themeDirection: 'ltr',
            themeContrast: 'default',
            themeLayout: 'mini',
            themeColorPresets: 'blue',
            themeStretch: true
          }}
        >
          <ThemeProvider>
            <MotionLazy>
              <SnackbarProvider>
                <ConstantsProvider>
                  <SignalRProvider>
                    <AppContent />
                  </SignalRProvider>
                </ConstantsProvider>
              </SnackbarProvider>
            </MotionLazy>
          </ThemeProvider>
        </SettingsProvider>
      </LocalizationProvider>
    </AuthProvider>
  );
}
