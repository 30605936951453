import { createContext, useCallback, useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  checkPermission,
  checkResourceAccess,
  checkResourceGroupAccess
} from '../utils/permission-utils';

/**
 * @typedef {Object} Permission
 * @property {('View'|'Create'|'Edit'|'Delete')} action
 * @property {string} resource
 * @property {('SELF'|'TEAM'|'ALL')} [scope]
 */

/**
 * @typedef {Object} Role
 * @property {string} id
 * @property {string} name
 * @property {string} [description]
 * @property {Permission[]} permissions
 * @property {string[]} resourceGroups
 */

/**
 * @typedef {Object} AuthUser
 * @property {string} id
 * @property {string} email
 * @property {Role[]} roles
 * @property {Permission[]} permissions
 * @property {string[]} resourceGroups
 * @property {string} [teamId]
 */

const AuthorizationContext = createContext(null);

export function AuthorizationProvider({ children, user }) {
  // Transform JWT user data to our authorization format
  const authUser = useMemo(() => {
    if (!user) return null;

    return {
      id: user.id,
      email: user.email,
      roles: user.roles || [],
      permissions: user.permissions || [],
      resourceGroups: user.resourceGroups || [],
      teamId: user.teamId
    };
  }, [user]);

  /**
   * Check if user has specific permission
   * @param {string} permission - Permission string in format "resource.action[.scope]"
   * @returns {boolean}
   */
  const hasPermission = useCallback(
    (permission) => {
      return checkPermission(authUser, permission);
    },
    [authUser]
  );

  /**
   * Check if user has access to specific resource group
   * @param {string} groupId - Resource group ID
   * @returns {boolean}
   */
  const hasResourceGroupAccess = useCallback(
    (groupId) => {
      return checkResourceGroupAccess(authUser, groupId);
    },
    [authUser]
  );

  /**
   * Check if user can access specific resource with given action
   * @param {Object} resource - Resource to check access for
   * @param {string} action - Action to check
   * @returns {boolean}
   */
  const canAccessResource = useCallback(
    (resource, action) => {
      return checkResourceAccess(authUser, resource, action);
    },
    [authUser]
  );

  // Memoize context value to prevent unnecessary re-renders
  const value = useMemo(
    () => ({
      isAuthenticated: !!authUser,
      isInitialized: true,
      user: authUser,
      hasPermission,
      hasResourceGroupAccess,
      canAccessResource
    }),
    [authUser, hasPermission, hasResourceGroupAccess, canAccessResource]
  );

  return <AuthorizationContext.Provider value={value}>{children}</AuthorizationContext.Provider>;
}

AuthorizationProvider.propTypes = {
  children: PropTypes.node,
  user: PropTypes.shape({
    id: PropTypes.string,
    email: PropTypes.string,
    roles: PropTypes.array,
    permissions: PropTypes.array,
    resourceGroups: PropTypes.array,
    teamId: PropTypes.string
  })
};

export const useAuthorization = () => {
  const context = useContext(AuthorizationContext);

  if (!context) {
    throw new Error('useAuthorization must be used within AuthorizationProvider');
  }

  return context;
};
