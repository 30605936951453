import { memo } from 'react';
// @mui
import { useTheme } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
// theme
import { bgBlur } from 'src/theme/css';
// hooks
import { useAuthContext } from 'src/auth/hooks';
import { useLocales } from 'src/locales';
// components
import { NavSectionHorizontal } from 'src/components/nav-section';
// utils
import { filterNavigation, getMinimalNavigation } from 'src/auth/utils/navigation-utils';
//
import { HEADER } from '../config-layout';
import { useNavData } from './config-navigation';
import { HeaderShadow } from '../_common';

// ----------------------------------------------------------------------

function NavHorizontal() {
  const theme = useTheme();
  const { t } = useLocales();
  const { user } = useAuthContext();
  const baseNavData = useNavData();

  // Get appropriate navigation data based on user role
  const navData = user?.role ? filterNavigation(baseNavData, user) : getMinimalNavigation(t);

  // Don't render if no navigation items are available
  if (!navData.length) {
    return null;
  }

  return (
    <AppBar
      component="nav"
      sx={{
        top: HEADER.H_DESKTOP_OFFSET
      }}
    >
      <Toolbar
        sx={{
          ...bgBlur({
            color: theme.palette.background.default
          })
        }}
      >
        <NavSectionHorizontal
          data={navData}
          config={{
            currentRole: user?.role || ''
          }}
          sx={{
            bgcolor: 'background.neutral'
          }}
        />
      </Toolbar>

      <HeaderShadow />
    </AppBar>
  );
}

export default memo(NavHorizontal);
