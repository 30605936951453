import PropTypes from 'prop-types';
import { useAuthorization } from '../context/authorization-context';

/**
 * A component that renders its children only if the user has the required permissions
 * @param {Object} props
 * @param {string} [props.requiredPermission] - Required permission in format "resource.action[.scope]"
 * @param {string} [props.requiredGroup] - Required resource group ID
 * @param {Object} [props.resource] - Resource to check access for
 * @param {string} [props.action] - Action to check for resource access
 * @param {React.ReactNode} props.children - Children to render if authorized
 */
export default function ProtectedElement({
  requiredPermission,
  requiredGroup,
  resource,
  action,
  children
}) {
  const { hasPermission, hasResourceGroupAccess, canAccessResource } = useAuthorization();

  // Check if user has required permission
  if (requiredPermission && !hasPermission(requiredPermission)) {
    return null;
  }

  // Check if user has access to required resource group
  if (requiredGroup && !hasResourceGroupAccess(requiredGroup)) {
    return null;
  }

  // Check if user can access the resource with given action
  if (resource && action && !canAccessResource(resource, action)) {
    return null;
  }

  return children;
}

ProtectedElement.propTypes = {
  requiredPermission: PropTypes.string,
  requiredGroup: PropTypes.string,
  resource: PropTypes.object,
  action: PropTypes.oneOf(['View', 'Create', 'Edit', 'Delete']),
  children: PropTypes.node.isRequired
};
