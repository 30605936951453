// routes
import { paths } from 'src/routes/paths';
// utils
import axios from 'src/utils/axios';

// ----------------------------------------------------------------------

const STORAGE_KEY = 'accessToken';
const REFRESH_TOKEN_KEY = 'refreshToken';
const STORAGE_TYPE_KEY = 'tokenStorageType';

function jwtDecode(token) {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split('')
      .map((c) => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
      .join('')
  );

  return JSON.parse(jsonPayload);
}

// ----------------------------------------------------------------------

export const isValidToken = (accessToken) => {
  if (!accessToken) {
    return false;
  }

  const decoded = jwtDecode(accessToken);

  const currentTime = Date.now() / 1000;

  return decoded.exp > currentTime;
};

// ----------------------------------------------------------------------

export const tokenExpired = (exp) => {
  // eslint-disable-next-line prefer-const
  let expiredTimer;

  const currentTime = Date.now();
  const timeLeft = exp * 1000 - currentTime;

  clearTimeout(expiredTimer);

  expiredTimer = setTimeout(() => {
    refreshAccessToken(); // Try to refresh token before logging out
  }, timeLeft);
};

// ----------------------------------------------------------------------

export const getRefreshToken = () => {
  const storageType = localStorage.getItem(STORAGE_TYPE_KEY);
  return storageType === 'local'
    ? localStorage.getItem(REFRESH_TOKEN_KEY)
    : sessionStorage.getItem(REFRESH_TOKEN_KEY);
};

export const refreshAccessToken = async () => {
  try {
    const refreshToken = getRefreshToken();

    if (!refreshToken) {
      throw new Error('No refresh token available');
    }

    const response = await axios.post('/api/auth/refresh-token', {
      refreshToken
    });

    const { accessToken, refreshToken: newRefreshToken } = response.data;

    // Get current storage type
    const storageType = localStorage.getItem(STORAGE_TYPE_KEY);
    const remember = storageType === 'local';

    // Update tokens
    setSession(accessToken, newRefreshToken, remember);

    return accessToken;
  } catch (error) {
    console.error('Error refreshing token:', error);
    // If refresh fails, log out the user
    setSession(null);
    window.location.href = paths.auth.jwt.login;
    throw error;
  }
};

// ----------------------------------------------------------------------

export const setSession = (accessToken, refreshToken, remember = false) => {
  if (accessToken) {
    // Store tokens in the appropriate storage
    if (remember) {
      localStorage.setItem(STORAGE_KEY, accessToken);
      localStorage.setItem(REFRESH_TOKEN_KEY, refreshToken);
      localStorage.setItem(STORAGE_TYPE_KEY, 'local');
      sessionStorage.removeItem(STORAGE_KEY);
      sessionStorage.removeItem(REFRESH_TOKEN_KEY);
    } else {
      sessionStorage.setItem(STORAGE_KEY, accessToken);
      sessionStorage.setItem(REFRESH_TOKEN_KEY, refreshToken);
      localStorage.removeItem(STORAGE_KEY);
      localStorage.removeItem(REFRESH_TOKEN_KEY);
      localStorage.setItem(STORAGE_TYPE_KEY, 'session');
    }

    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;

    // This function below will handle when token is expired
    const { exp } = jwtDecode(accessToken);
    tokenExpired(exp);
  } else {
    localStorage.removeItem(STORAGE_KEY);
    localStorage.removeItem(REFRESH_TOKEN_KEY);
    sessionStorage.removeItem(STORAGE_KEY);
    sessionStorage.removeItem(REFRESH_TOKEN_KEY);
    localStorage.removeItem(STORAGE_TYPE_KEY);

    delete axios.defaults.headers.common.Authorization;
  }
};
