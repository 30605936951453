import { checkPermission } from './permission-utils';

/**
 * Check if a navigation item should be visible based on required permissions
 * @param {Object} item - Navigation item to check
 * @param {Object} user - Current user
 * @returns {boolean}
 */
export const canViewNavItem = (item, user) => {
  if (!user || !item) return false;

  // If no permissions specified, item is visible
  if (!item.permissions) {
    return true;
  }

  // Check if user has admin role
  const isAdmin = user.roles?.some((role) => role.name === 'Admin');
  if (isAdmin) {
    return true;
  }

  // Check required permissions if specified
  const hasAllPermissions = item.permissions.every((permission) =>
    checkPermission(user, permission)
  );
  if (!hasAllPermissions) return false;

  // Check children recursively
  if (item.children) {
    const hasVisibleChildren = item.children.some((child) => canViewNavItem(child, user));
    return hasVisibleChildren;
  }

  return true;
};

/**
 * Filter navigation data based on user permissions
 * @param {Array} navData - Navigation data array
 * @param {Object} user - Current user
 * @returns {Array} - Filtered navigation data
 */
export const filterNavigation = (navData, user) => {
  if (!user || !navData) return [];

  // If user is admin, return all navigation items
  const isAdmin = user.roles?.some((role) => role.name === 'Admin');
  if (isAdmin) {
    return navData;
  }

  return navData
    .map((section) => {
      // Filter items in each section
      const filteredItems = section.items?.filter((item) => canViewNavItem(item, user)) || [];

      // Only include sections with visible items
      return filteredItems.length > 0
        ? {
            ...section,
            items: filteredItems.map((item) => ({
              ...item,
              children: item.children?.filter((child) => canViewNavItem(child, user))
            }))
          }
        : null;
    })
    .filter(Boolean);
};

/**
 * Get minimal navigation for users with no role
 * @param {Function} t - Translation function
 * @returns {Array} - Basic navigation items
 */
export const getMinimalNavigation = (t) => [
  {
    subheader: t('account'),
    items: [
      {
        title: t('profile'),
        path: '/profile',
        icon: 'ph:user-circle-duotone'
      },
      {
        title: t('help'),
        path: '/help',
        icon: 'ph:question-duotone'
      }
    ]
  }
];
